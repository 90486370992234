const cloud2Router = [
        {
            path: "/cloud2",
            name: "cloud2",
            component: () =>
                import("../views/bigScreen/cloud2/index.vue"),
        },
    {
        path: "/factory",
        name: "factory",
        component: () =>
            import("../views/bigScreen/cloud2/fatory.vue"),
    },
    {
        path: "/chanye",
        name: "chanye",
        component: () =>
            import("../views/bigScreen/cloud2/chanye.vue"),
    },
    {
        path: "/baoxian",
        name: "baoxian",
        component: () =>
            import("../views/bigScreen/cloud2/baoxian.vue"),
    },
    {
        path: "/yanjiuyuan",
        name: "yanjiuyuan",
        component: () =>
            import("../views/bigScreen/cloud2/yanjiuyuan.vue"),
    },
    {
        path: "/dataScreen",
        name: "dataScreen",
        component: () =>
            import("../views/bigScreen/cloud2/dataCenter.vue"),
    },
    {
        path: "/cloud3",
        name: "cloud3",
        component: () =>
            import("../views/bigScreen/hancheng/index.vue"),
    },

    {
        path: "/info",
        name: "info",
        component: () =>
            import("../views/bigScreen/cloud2/info.vue"),
    },


];
export default cloud2Router;